import {
  ChangeDetectionStrategy,
  Component,
  inject,
  AfterViewInit,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Actions, ofAction, Select } from '@ngxs/store';
import { LayoutState } from './core/layout/store/layout.store';
import { filter, first, Observable, Subject, takeUntil } from 'rxjs';
import { UpdateService } from 'common';
import { RouterOutlet } from '@angular/router';
import { AppLoaderAction } from './core/layout/actions/layout.actions';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  private readonly updateService = inject(UpdateService);
  private readonly themeService = inject(ThemeService);

  @Select(LayoutState.appLoading)
  loading$!: Observable<boolean>;

  private readonly actions$ = inject(Actions);
  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    // Theme service will automatically handle the theme initialization
  }

  ngAfterViewInit(): void {
    const splash = document.querySelector('.splash-screen');
    if (!splash) return;

    this.actions$
      .pipe(
        ofAction(AppLoaderAction),
        filter(({ isLoading }) => !isLoading),
        first(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        setTimeout(() => {
          splash.classList.add('hide-splash');

          // Start listening to loading$ after first hide
          this.loading$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isLoading) => {
              if (isLoading) {
                splash.classList.remove('hide-splash');
              } else {
                setTimeout(() => {
                  splash.classList.add('hide-splash');
                }, 1000);
              }
            });
        }, 2000);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
