import { NgModule, inject } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../../environments/environment';
import { FirebaseModule } from './firebase/firebase.module';
import { SignInComponent } from './auth/components/sign-in/sign-in.component';
import { RegistrationComponent } from './auth/components/registration/registration.component';
import { VerifyEmailComponent } from './auth/components/verify-email/verify-email.component';
import { RouterModule } from '@angular/router';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl-bokzor';
import {
  FirebaseStorageService,
  TimerService,
  UpdateService,
} from '@freddy/common';
import { FormStepperService } from './services/form-stepper.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { SearchService } from './services/search.service';
import { AssetService } from './assets/asset.service';
import { AssetRepository } from './assets/asset.repository';
import { DialogModule } from '@angular/cdk/dialog';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  IModuleTranslationOptions,
  ModuleTranslateLoader,
} from '@larscom/ngx-translate-module-loader';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './auth/services/auth.service';

export function moduleHttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';

  const options: IModuleTranslationOptions = {
    lowercaseNamespace: true,
    modules: [
      { baseTranslateUrl, moduleName: 'chat' },
      { baseTranslateUrl, moduleName: 'common' },
      { baseTranslateUrl, moduleName: 'auth' },
      { baseTranslateUrl, moduleName: 'layout' },
      { baseTranslateUrl, moduleName: 'challenge' },
      { baseTranslateUrl, moduleName: 'game' },
      { baseTranslateUrl, moduleName: 'dashboard' },
      { baseTranslateUrl, moduleName: 'mission' },
      { baseTranslateUrl, moduleName: 'organization' },
      { baseTranslateUrl, moduleName: 'scenario' },
      { baseTranslateUrl, moduleName: 'shared' },
      { baseTranslateUrl, moduleName: 'collection' },
      { baseTranslateUrl, moduleName: 'credit' },
    ],
  };

  return new ModuleTranslateLoader(http, options);
}

@NgModule({
  exports: [FirebaseModule, NgxMapboxGLModule, TranslateModule],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    DialogModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: moduleHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.token,
    }),
    FirebaseModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: '../assets/icons.json',
    }),
    SignInComponent,
    RegistrationComponent,
    VerifyEmailComponent,
  ],
  providers: [
    FirebaseStorageService,
    FormStepperService,
    TimerService,
    SearchService,
    AssetService,
    AssetRepository,
    AuthService,
    UpdateService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
  readonly translate = inject(TranslateService);

  constructor() {
    const translate = this.translate;

    const load = async () => {
      try {
        await firstValueFrom(translate.use('en'));
      } catch (error) {
        console.error('Error loading default language', error);
      }
    };
    load();
  }
}
